.banner-sc {
  padding: 5rem 3rem;
  background-size: 67%;
  background-repeat: no-repeat;
  background-position: right top;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../../../components/images/bg-light.png');
    background-size: contain;
    width: 70%;
    height: 100%;
    background-repeat: no-repeat;
  }

  .content-row {
    position: relative;
    z-index: 1;
    align-items: center;
    .banner-contents {
      h1 {
        font-size: 42px;
        font-weight: 600;
        color: #1c233c;
        margin-bottom: 2rem;
      }
    }
  }
}
.common-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;
  li {
    font-size: 18px;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
      background-image: url('../../images/check.svg');
      background-size: 100%;
      width: 20px;
      height: 20px;
    }
  }
}
@include media-breakpoint-up(xl) {
  .banner-sc {
    min-height: 855px;
  }
}

@include media-breakpoint-down(lg) {
  .banner-sc {
    .content-row {
      .banner-contents {
        h1 {
          font-size: 35px;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .banner-sc {
    padding: 5rem 1rem;
    .content-row {
      flex-direction: column-reverse;
      .banner-contents {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
  .common-list {
    li {
      font-size: 16px;
    }
  }
}
