.skill-header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  z-index: 101;
  @include transition(all 0.25s ease);
  &.sticky {
    box-shadow: $box-shadow-sm;
    @include box-shadow(0 5px 12px rgba($black, 0.01));
    background-color: rgba(255, 255, 255, 1);
    .hdr-main {
      padding: 0.7rem 1rem;
    }
  }
  .btn {
    padding-left: 2rem;
    padding-right: 2rem;
    transition: all ease 0.25s;
    &:hover {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    }
  }
}
.hdr-main {
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include transition(all 0.3s ease);
  .logobox {
    max-width: 200px;
    width: 100%;
    transition: all ease 0.25s;
    img {
      width: 100%;
    }
  }
  .hdr-main-nav {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      li {
        padding: 0 22px;
        font-size: 1.25rem;
        a {
          padding: 0.75rem 0;
          text-decoration: none;
          transition: all ease 0.25s;
          color: #fff;
          &:hover {
            color: $primary;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .get-startd {
        font-weight: 700;
      }
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 3rem 2rem;
      background-color: $black;
      @include transition(all 0.3s ease);

      &.open {
        top: 0;
      }

      ul {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100%;
        align-items: normal;
        li {
          padding: 8px 0;
          a {
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.mob-menu {
  width: 32px;
  height: 32px;
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.active {
    position: fixed;
    top: 18px;
    right: 10px;
    z-index: 12;
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-7px, 5px);
      transform: rotate(-45deg) translate(-7px, 5px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      -webkit-transform: rotate(45deg) translate(-6px, -4px);
      transform: rotate(45deg) translate(-6px, -4px);
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 23px;
    height: 3px;
    background-color: $primary;
    margin: 5px 0;
    transition: 0.4s;
  }
}
@include media-breakpoint-down(md) {
  .hdr-main {
    padding: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .mob-menu {
    display: none;
  }
}
@include media-breakpoint-down(sm) {
  .skill-header {
    .btn {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .hdr-main {
    .logobox {
      max-width: 138px;
    }
  }
}
