.btn-primary-group {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 10px;
    width: 25px;
  }
}
.btn {
  font-weight: 600;
}
.main-screen-outer {
  background: linear-gradient(87deg, #b8c3ff, #c5eef3);
  padding: 2rem;
  padding-right: 0;
  border-radius: 16px;
  h3 {
    font-size: 25px;
    color: #1c233c;
    font-weight: 600;
  }
}
.main-screen {
  margin-top: 1rem;
  margin-right: -1rem;
  .screen-items {
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
}
.slider-sc {
  padding-bottom: 7rem;
  margin-top: -2rem;
  position: relative;
}
.streamline-sc {
  padding: 4.75rem 0;
  background-size: cover;
  .stream-row {
    margin-bottom: 80px;
    .stream-box {
      background: #e4f6ff;
      border-radius: 12px;
      padding: 1.5rem 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .icon-box {
        background: $primary;
        width: 87px;
        height: 87px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto auto 1rem auto;
        img {
          height: 45px;
          width: 40px;
          object-fit: contain;
        }
      }
      h5 {
        font-size: 16px;
        color: #343f5a;
        font-weight: 600;
      }
    }
  }
}
.main-ttl {
  font-size: 40px;
  font-weight: 600;
  max-width: 680px;
  margin: auto;
  margin-bottom: 75px;
  color: #2e395a;
}

.go-sc {
  display: flex;
  justify-content: center;
}
.admin-sc {
  position: relative;
  padding: 7rem 0;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('../../images/light-bg-2.png');
    width: 70%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .admin-rows {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .admin-container {
    position: relative;
    z-index: 1;
    .designation-image {
      img {
        width: 100%;
      }
    }
    .designation-content {
      h3 {
        font-size: 28px;
        max-width: 426px;
        color: #000;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      h4 {
        color: #505e83;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 1.857rem;
      }
    }
  }
  .des-button {
    .btn {
      &:hover {
        color: #fff;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.begin-sc {
  .begin-box {
    padding: 4.5rem 2rem;
    background-size: cover;
    border-radius: 36px;
    h3 {
      font-size: 38px;
      font-weight: 600;
      text-align: center;
      color: #000;
      margin-bottom: 1rem;
    }
    h2 {
      color: #000;
      max-width: 830px;
      margin: auto;
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      margin-bottom: 2rem;
      line-height: 60px;
    }
    .btn {
      margin: auto;
    }
  }
}
.btn {
  transition: all ease 0.25s;
  &:hover {
    padding: 0.55rem 1.3rem;
  }
}
.footer-top {
  padding: 6rem 0;
  .leftbox {
    .footerlogo {
      margin-bottom: 1rem;
      max-width: 200px;
    }
    .maps {
      img {
        width: 90%;
      }
    }
  }
  .right-box {
    h3 {
      color: #526edb;
      font-size: 30px;
      max-width: 404px;
      margin-bottom: 2rem;
    }
    .form-control {
      border: 0;
      border-radius: 0;
      padding-left: 0;
      border-bottom: solid 1px #d0d0d0;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.floating-label-group {
  position: relative;
  margin-top: 20px;
  margin-bottom: 25px;

  .floating-label {
    font-size: 14px;
    font-weight: 600;
    color: #505e83;
    position: absolute;
    pointer-events: none;
    top: 2px;
    left: 0;
    transition: all 0.3s ease;
  }

  input:focus ~ .floating-label,
  input:not(:focus):not([value=""]) ~ .floating-label {
    top: -15px;
    bottom: 0px;
    left: 0px;
    font-size: 11px;
    opacity: 1;
    color: #404040;
  }
}
.footer-sc {
  background: $primary;
  padding: 23px 0;
}
.social-media {
  ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    justify-content: end;
    li {
      margin: 0 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .stream-row {
    .col {
      width: 100%;
      flex: 0 0 50%;
    }
  }
  .main-ttl {
    font-size: 32px;
    margin-bottom: 29px;
  }
  .begin-sc {
    .begin-box {
      padding: 2.5rem 2rem;
      h3 {
        font-size: 28px;
      }
      h2 {
        font-size: 26px;
        line-height: 33px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .social-media {
    ul {
      justify-content: center;
      margin-top: 10px;
      padding-left: 0;
    }
  }
  .slider-sc {
    padding-bottom: 3rem;
  }
  .streamline-sc {
    padding: 2.5rem 0;
    .stream-row {
      margin-bottom: 30px;
    }
  }
  .admin-sc {
    padding: 2.5rem 0;
    .admin-container {
      .designation-content {
        h3 {
          font-size: 21px;
        }
        h4 {
          font-size: 19px;
        }
      }
    }
  }
  .admin-rows {
    &:nth-child(3) {
      flex-direction: column-reverse;
    }
  }
  .main-screen {
    margin-right: auto;
  }
  .main-screen-outer {
    padding-right: 2rem;
    padding-bottom: 4rem;
    h3 {
      font-size: 22px;
    }
  }
  .footer-top {
    padding: 3rem 0;
  }
}

@include media-breakpoint-down(sm) {
  .btn {
    font-size: 14px;
    padding: 0.35rem 1rem;
  }
  .footer-top {
    .right-box {
      h3 {
        font-size: 22px;
      }
    }
    .leftbox {
      .footerlogo {
        max-width: 100px;
      }
    }
  }

  .stream-row {
    .col {
      width: 100%;
      flex: 0 0 100%;
    }
  }
  .main-ttl {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .admin-rows {
    &:nth-child(3) {
      background: #eff5ff;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .begin-sc {
    .begin-box {
      padding: 2.5rem 2rem;
      h3 {
        font-size: 23px;
      }
      h2 {
        font-size: 22px;
        line-height: 29px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .policy {
    
    margin-left: 20px !important;
    margin-right: 20px !important;
    text-align: justify !important;
  }
}
@media screen and (min-width: 600px) {
  .policy {
    
    margin-left: 40px !important;
    margin-right: 40px !important;
    text-align: justify !important;
  }
}
@media screen and (min-width: 900px) {
  .policy {
    
    margin-left: 200px !important;
    margin-right: 200px !important;
    text-align: justify !important;
  }
}
@media only screen and (max-width: 300px) {
  .policy-date strong {
    display: block; /* Display <strong> elements as block to force new line */
    // margin-bottom: 5px; /* Add some space between lines */
  }
}
.policy{
  color: #000000dc;
  ul{
    // list-style-type: none;
    padding: 0;
    
    li a {
      margin-right: -4px; /* Adjust as needed */
  }
  }
  
  .policy-date {
    white-space: nowrap; /* Prevent line breaks */
    padding-top: 10px;
  }
}
